* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

.mb-3 {
  margin-bottom: 15px;
}

.main {
  display: flex;
  height: 100vh;

}

.main .sidebar {
  width: 260px;
  background-color: #44318d;
}

.main .contnet-wrapper {
  width: calc(100vw - 260px);
  margin: 14px;
}

.main .inner-contnet-wrapper {
  width: 100%;
  max-width: 750px;
  border: 1px solid #D0D7DE;
  border-radius: 16px;
  padding: 14px;
}

.main .label_name {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #1F2328;
  padding-bottom: 6px;
  display: inline-block;
}

.main .contnet-wrapper {
  padding: 14px;
}

.sidebar {
  flex: 0 0 250px; /* Adjust sidebar width as needed */
  background-color: #f0f0f0;
  padding: 20px;
}

.sidebar-chip {
  background-color: #35266F;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-top: 70px;
}

.sidebar .sidebar-content {
  font-weight: bold;
  display: flex;
}


/* .sidebar .sidebar-content{
    display: flex;
    justify-content: flex-start;
    margin-block-start: 100px;
    margin-left: 50px;
    color: #ccc;
} */

.main .input_box {
  padding-bottom: 14px;
}

.main .input_field {
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: black;
  padding: 14px 9px;
  display: block;
  width: 100%;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.primary_btn {
  background: #44318D;
  border-radius: 10px;
  padding: 10px 17px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  color: #fff;
  border: none;
  display: inline-block;
  margin-top: 12px;
}

.btn-wrapper {
  display: flex;
  column-gap: 10px;
}


/* Custom Date Picker Start */
.custom-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.custom-datepicker .react-datepicker__close-icon::after {
  background-color: #f2f4f7;
  padding: 0px 1px 1px 1px;
  color: #000000;
  font-size: 16px;
}

.custom-datepicker .react-datepicker__close-icon:hover::after {
  background-color: #e6e7e8;
}

.custom-datepicker .react-datepicker__close-icon {
  padding-right: 0px;
}

.custom-datepicker .react-datepicker {
  box-shadow: 0px 0px 8px 8px #e8e8e84a;
  font-family: "Inter", sans-serif !important;
  border: 1px solid #e0e0e0;
}

.custom-datepicker .react-datepicker__header {
  background-color: #fff;
  color: #44318D;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0;
}

.custom-datepicker .react-datepicker__navigation-icon::before {
  border-color: #344054;
  border-width: 2px 2px 0 0;
}

.custom-datepicker .react-datepicker__navigation-icon:hover::before,
.custom-datepicker .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
  border-color: #44318D;
}

.custom-datepicker .react-datepicker__navigation {
  top: 12px;
}

.custom-datepicker .react-datepicker__current-month,
.custom-datepicker .react-datepicker-time__header,
.custom-datepicker .react-datepicker-year-header {
  color: #344054;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  padding-bottom: 10px;
  font-size: 18px;
}

.custom-datepicker .react-datepicker__day-name {
  color: #344054;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
}

.custom-datepicker .react-datepicker__day--in-range,
.custom-datepicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: #44318D !important;
  color: #fff;
}

.custom-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #44318D !important;
  color: #fff;
  font-weight: 500;
}

.custom-datepicker .react-datepicker__day {
  padding: 7px;
  width: 42px;
  border-radius: 50% !important;
  margin: 6px 3px;
}

.custom-datepicker.width-adjust .react-datepicker__day {
  padding: 5px 5px;
  width: 38px;
  margin: 0px 2px;
}

.main .heading {
  font-size: 22px;
  font-weight: 600;
  color: #44318D;
  padding: 0px 0px 14px 0px;
  border-bottom: 1px solid #D0D7DE;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 14px;
}

.generated-response {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  white-space: pre-wrap;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.rich_text_editor .ql-container {
  height: 300px;  /* Set your desired height */
}

.rich_text_editor .ql-editor {
  min-height: 300px;  /* Ensure the text area inside also grows */
}